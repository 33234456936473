import React, { useEffect, useState } from 'react'
import axios from 'axios'
import styles from './portal.module.scss'
import jwt from 'jsonwebtoken'
import moment from 'moment'
import Loader from 'react-loader-spinner'
import Header from '../header/header'
import Footer from '../footer/footer'
import { navigate } from 'gatsby'
import Button from '../buttons/button'
import PropTypes from 'prop-types'

const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  })

const MAX_LOAD = 50;

const Attachment = ({ id, filename, token }) => {
  const [image, setImage] = useState(null)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const openAttachment = () => setOpen(true)

  const close = () => setOpen(false)

  const loadImage = async () => {
    const res = await window.fetch(`https://mcntags.com/api/get-image`, {
      method: "post",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ id: id.toUpperCase(), filename })
    })

    if (!res || res.status !== 200 || !res.headers.get("Content-Type")) {
      throw new Error("There was a problem loading the file")
    }
    const type = res.headers.get("Content-Type")
  
    const blob = await res.blob();
    const file = new Blob(
      [blob],
      {type}
    );

    const fileUrl = URL.createObjectURL(file)
    return { fileUrl, type }
  }

  const openInNewWindow = async () => {
    setLoading(true)
    const { fileUrl } = await loadImage()
    setLoading(false)
    window.open(fileUrl, '_blank')
  }

  useEffect(() => {
    if (!filename || !open) {
      return
    }

    (async() => {
      setLoading(true)
      const { fileUrl, type } = await loadImage()
      setLoading(false)
      setImage({ img: fileUrl, pdf: type.includes('pdf') })
    })()
    
  }, [open])

  const style = `${styles.attachment}${open ? ` ${styles.open}` : ''}`

  return (
    <>
      <button
        className={styles.pageButton + ` ${styles.desktopAttachment}`}
        onClick={openAttachment}>
          {filename}
      </button>
      <button
        className={styles.pageButton + ` ${styles.mobileAttachment}`}
        onClick={openInNewWindow}>
          {filename}
      </button>
      <div className={style}>
        {loading && <Loading />}
        {loading && <div className={styles.filler} />}
        {image && image.pdf && <embed id={`${id}-image`} src={image.img} />}
        {image && !image.pdf && <img id={`${id}-image`} src={image.img} alt='' />}
        <PortalButton {...{ label: 'CLOSE', onClick: close, className: styles.secondary }} />
      </div>
    </>
    
  )
}

Attachment.propTypes = {
  id: PropTypes.any,
  filename: PropTypes.string,
  token: PropTypes.string,
}

const TagTransaction = ({ transaction, showBorder, token }) => {
  const style = styles.transaction + `${showBorder ? ` ${styles.last}` : ''}`

  return (
    <div className={style}>
      <div className={styles.label}>
        <div>Date</div>
        <div>Roll Number</div>
        <div>Tag</div>
        <div>VIN</div>
        <div>Email</div>
      </div>
      <div>
        <div className={styles.value}>{moment(transaction.date).format('MM/DD/YYYY hh:mm:ss a')}</div>
        <div className={styles.value}>{transaction.roll_number}</div>
        <div className={styles.value}>{transaction.plate_number}</div>
        <div className={styles.value}>{transaction.vin}</div>
        <div className={styles.value}>{transaction.email}</div>
      </div>
      <div className={styles.label}>
        <div>Address1</div>
        <div>Address2</div>
        <div>City</div>
        <div>State</div>
        <div>Zip</div>
      </div>
      <div>
        <div className={styles.value}>{transaction.address1}</div>
        <div className={styles.value}>{transaction.address2 ?? ''}</div>
        <div className={styles.value}>{transaction.city}</div>
        <div className={styles.value}>{transaction.state}</div>
        <div className={styles.value}>{transaction.zip}</div>
      </div>
      <div className={styles.label}>
        <div>Phone</div>
        <div>Cardholder</div>
        <div>Fee</div>
        <div>Penalty</div>
        <div>Total</div>
      </div>
      <div>
        <div className={styles.value}>{transaction.phone}</div>
        <div className={styles.value}>{transaction.cardholder}</div>
        <div className={styles.value}>{formatter.format(transaction.fee)}</div>
        <div className={styles.value}>{formatter.format(transaction.penalty)}</div>
        <div className={styles.value}>{formatter.format(transaction.total)}</div>
      </div>
      <div className={styles.attachmentContainer}>
        {transaction.filename && <div className={styles.buttonContainer + ` ${styles.fileButtons}`}>
          <Attachment {...{ id: transaction.vin, filename: transaction.filename, token }} />
        </div>}
      </div>
    </div>
  )
}

TagTransaction.propTypes = {
  transaction: PropTypes.object,
  showBorder: PropTypes.bool,
  token: PropTypes.string,
}

const BoatTransaction = ({ transaction, showBorder, token }) => {
  const style = styles.transaction + `${showBorder ? ` ${styles.last}` : ''}`

  return (
    <div className={style}>
      <div className={styles.label}>
        <div>Date</div>
        <div>Year</div>
        <div>HIN / Serial #</div>
      </div>
      <div>
        <div className={styles.value}>{moment(transaction.date).format('MM/DD/YYYY hh:mm:ss a')}</div>
        <div className={styles.value}>{transaction.boat_year}</div>
        <div className={styles.value}>{transaction.boat_number}</div>
      </div>
      <div className={styles.label}>
        <div>Name</div>
        <div>Email</div>
        <div>Roll Number</div>
      </div>
      <div>
        <div className={styles.value}>{transaction.name}</div>
        <div className={styles.value}>{transaction.email}</div>
        <div className={styles.value}>{transaction.roll_number}</div>
      </div>
      <div className={styles.label}>
        <div>Cardholder</div>
        <div>Phone</div>
        <div>Fee</div>
      </div>
      <div>
        <div className={styles.value}>{transaction.cardholder}</div>
        <div className={styles.value}>{transaction.phone}</div>
        <div className={styles.value}>{transaction.fee}</div>
      </div>
    </div>
  )
}

BoatTransaction.propTypes = {
  transaction: PropTypes.object,
  showBorder: PropTypes.bool,
  token: PropTypes.string,
}

const FormTransaction = ({ transaction, showBorder, token }) => {
  const style = styles.transaction + `${showBorder ? ` ${styles.last}` : ''}`

  return (
    <div className={style}>
      <div className={styles.label}>
        <div>Date</div>
        <div>Roll Number</div>
        <div>Tag</div>
      </div>
      <div>
        <div className={styles.value}>{moment(transaction.date).format('MM/DD/YYYY hh:mm:ss a')}</div>
        <div className={styles.value}>{transaction.roll_number}</div>
        <div className={styles.value}>{transaction.plate_number}</div>
      </div>
      <div className={styles.label}>
        <div>Name</div>
        <div>Email</div>
      </div>
      <div>
        <div className={styles.value}>{transaction.name}</div>
        <div className={styles.value}>{transaction.email}</div>
      </div>
      <div className={styles.label}>
        <div>Cardholder</div>
        <div>Phone</div>
      </div>
      <div>
        <div className={styles.value}>{transaction.cardholder}</div>
        <div className={styles.value}>{transaction.phone}</div>
      </div>
      <div className={styles.attachmentContainer}>
        <div className={styles.buttonContainer + ` ${styles.fileButtons}`}>
          {JSON.parse(transaction.filenames).map((f, idx) =>
            <Attachment key={idx} {...{ id: transaction.transaction_id, filename: f, token }} />
          )}
        </div>
      </div>
    </div>
  )
}

FormTransaction.propTypes = {
  transaction: PropTypes.object,
  showBorder: PropTypes.bool,
  token: PropTypes.string,
}

const VehicleInfoTransaction = ({ transaction, showBorder, token }) => {
  const style = styles.transaction + `${showBorder ? ` ${styles.last}` : ''}`

  return (
    <div className={style}>
      <div className={styles.label}>
        <div>Date</div>
        <div>Vin</div>
      </div>
      <div>
        <div className={styles.value}>{moment(transaction.date).format('MM/DD/YYYY hh:mm:ss a')}</div>
        <div className={styles.value}>{transaction.vin}</div>
      </div>
      <div className={styles.label}>
        <div>Name</div>
        <div>Email</div>
      </div>
      <div>
        <div className={styles.value}>{transaction.name}</div>
        <div className={styles.value}>{transaction.email}</div>
      </div>
      <div className={styles.label}>
        <div>Make</div>
        <div>Model</div>
      </div>
      <div>
        <div className={styles.value}>{transaction.make}</div>
        <div className={styles.value}>{transaction.model}</div>
      </div>
      <div className={styles.attachmentContainer}>
        <div className={styles.buttonContainer + ` ${styles.fileButtons}`}>
          {JSON.parse(transaction.filenames).map((f, idx) =>
            <Attachment key={idx} {...{ id: transaction.transaction_id, filename: f, token }} />
          )}
        </div>
      </div>
    </div>
  )
}

VehicleInfoTransaction.propTypes = {
  transaction: PropTypes.object,
  showBorder: PropTypes.bool,
  token: PropTypes.string,
}

const Field = ({ name, label, value, handleChange, placeholder, type, children }) => (
  <div className={styles.field}>
    <label htmlFor={name}>{label}</label>
    <input type={type} id={name} name={name} value={value} onChange={handleChange} placeholder={placeholder} />
    {children}
  </div>
)

Field.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.node,
}

const RequiredField = ({ name, label, handleChange, submit, type, formValues }) => (
  <Field {...{ type, name, label, handleChange, value: formValues[name] }} >
    <div className={styles.xLeft + ` ${submit && !formValues[name] ? styles.active : ""}`}>x</div>
  </Field>
)

RequiredField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  handleChange: PropTypes.func,
  submit: PropTypes.bool,
  type: PropTypes.string,
  formValues: PropTypes.object,
}

const Loading = () => (
  <div className={styles.loader}><Loader type="ThreeDots" color="#2BAF49" height={20} width={80} /></div>
)

const PortalButton = ({ label, onClick, className }) => (
  <button className={className} onClick={onClick}>{label}<span><img src="/arrow-right.svg" alt="" /></span></button>
)

PortalButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string
}

const LoginForm = ({ setToken }) => {
  const [formValues, setFormValues] = useState({ email: '', password: '' })
  const [submit, setSubmit] = useState(false)
  const [loader, setLoader] = useState(false)
  const [message, setMessage] = useState(false)

  const handleSubmit = async () => {
    setSubmit(true)
    const { email, password } = formValues

    if (email && password) {
      setLoader(true)
      setSubmit(false)

      try {
        const response = await axios.post("https://mcntags.com/api/login", { 
          email,
          password
        })
   
        setLoader(false)

        if (response.data && response.data.token && typeof window != 'undefined') {
          const resultToken = response.data.token
          setToken(resultToken)
          window.sessionStorage.setItem("mcntag-token", resultToken)
        } else {
          setMessage(true)
        }
      } catch (e) {
        setLoader(false)
        setMessage(true)
      }
    }
  }

  function handleChange(e) {
    const value = e.target.value;
    const name = e.target.name;
    setFormValues({ ...formValues, [name]: value })
  }

  const buttonProps = { formValues, handleChange, submit}

  return (
    <div className={styles.formContainer + ` ${styles.loginForm}`}>
      <form onSubmit={e => e.preventDefault()}>
        <div className={styles.fieldGroup}>
          <RequiredField {...{
            ...buttonProps, 
            name: 'email',
            label: 'Email',
          }} />
          <RequiredField {...{
            ...buttonProps, 
            name: 'password',
            label: 'Password',
            type: 'password'
          }} />
        </div>

        <p className={styles.message + ` ${message ? `${styles.active}` : ""}`}>
          Invalid login
        </p>
        <div className={styles.submitContainer}>
          {loader && <Loading />}
          <PortalButton {...{ label: 'LOGIN', onClick: handleSubmit }} />
          <PortalButton {...{ label: 'BACK', onClick: () => navigate('/'), className: styles.secondary }} />
        </div>
      </form>
    </div>
  )
}

LoginForm.propTypes = {
  setToken: PropTypes.func
}

const TagRenewalFilters = ({ formValues, handleChange }) => {
  return (
    <>
      <div className={styles.fieldGroup}>
        <Field {...{
          name: 'vin',
          label: 'Vin',
          value: formValues.vin,
          handleChange
        }} />
        <Field {...{
          name: 'tag',
          label: 'Tag',
          value: formValues.tag,
          handleChange
        }} />
      </div>
      <div className={styles.fieldGroup}>
        <Field {...{
          name: 'rollNumber',
          label: 'Roll Number',
          value: formValues.rollNumber,
          handleChange
        }} />
        <Field {...{
          name: 'date',
          label: 'Date',
          value: formValues.date,
          handleChange,
          placeholder: 'mm/dd/yyyy'
        }} />
      </div>
      <div className={styles.fieldGroup}>
        <Field {...{
          name: 'startDate',
          label: 'Start Date',
          value: formValues.startDate,
          handleChange,
          placeholder: 'mm/dd/yyyy'
        }} />
        <Field {...{
          name: 'endDate',
          label: 'End Date',
          value: formValues.endDate,
          handleChange,
          placeholder: 'mm/dd/yyyy'
        }} />
      </div>
      <div className={styles.fieldGroup}>
        <Field {...{
          name: 'cardHolder',
          label: 'Card Holder',
          value: formValues.cardHolder,
          handleChange
        }} />
        <Field {...{
          name: 'total',
          label: 'Total',
          value: formValues.total,
          handleChange,
          type: 'number'
        }} />
      </div>
    </>
  )
}

TagRenewalFilters.propTypes = {
  formValues: PropTypes.func,
  handleChange: PropTypes.func
}

const BoatRenewalFilters = ({ formValues, handleChange }) => {
  return (
    <>
      <div className={styles.fieldGroup}>
        <Field {...{
          name: 'boatNumber',
          label: 'Boat Number',
          value: formValues.boatNumber,
          handleChange
        }} />
        <Field {...{
          name: 'boatYear',
          label: 'Boat Year',
          value: formValues.boatYear,
          handleChange
        }} />
      </div>
      <div className={styles.fieldGroup}>
        <Field {...{
          name: 'rollNumber',
          label: 'Roll Number',
          value: formValues.rollNumber,
          handleChange
        }} />
        <Field {...{
          name: 'date',
          label: 'Date',
          value: formValues.date,
          handleChange,
          placeholder: 'mm/dd/yyyy'
        }} />
      </div>
      <div className={styles.fieldGroup}>
        <Field {...{
          name: 'startDate',
          label: 'Start Date',
          value: formValues.startDate,
          handleChange,
          placeholder: 'mm/dd/yyyy'
        }} />
        <Field {...{
          name: 'endDate',
          label: 'End Date',
          value: formValues.endDate,
          handleChange,
          placeholder: 'mm/dd/yyyy'
        }} />
      </div>
      <div className={styles.fieldGroup}>
        <Field {...{
          name: 'cardHolder',
          label: 'Card Holder',
          value: formValues.cardHolder,
          handleChange
        }} />
        <Field {...{
          name: 'fee',
          label: 'Fee',
          value: formValues.fee,
          handleChange,
          type: 'number'
        }} />
      </div>
      <div className={styles.fieldGroup}>
        <Field {...{
          name: 'name',
          label: 'Name',
          value: formValues.name,
          handleChange
        }} />
      </div>
    </>
  )
}

BoatRenewalFilters.propTypes = {
  formValues: PropTypes.func,
  handleChange: PropTypes.func
}

const FormFilters = ({ formValues, handleChange }) => {
  return (
    <>
      <div className={styles.fieldGroup}>
        <Field {...{
          name: 'name',
          label: 'Name',
          value: formValues.name,
          handleChange
        }} />
        <Field {...{
          name: 'cardholder',
          label: 'Cardholder',
          value: formValues.cardholder,
          handleChange
        }} />
      </div>
      <div className={styles.fieldGroup}>
        <Field {...{
          name: 'rollNumber',
          label: 'Roll Number',
          value: formValues.rollNumber,
          handleChange
        }} />
        <Field {...{
          name: 'date',
          label: 'Date',
          value: formValues.date,
          handleChange,
          placeholder: 'mm/dd/yyyy'
        }} />
      </div>
      <div className={styles.fieldGroup}>
        <Field {...{
          name: 'startDate',
          label: 'Start Date',
          value: formValues.startDate,
          handleChange,
          placeholder: 'mm/dd/yyyy'
        }} />
        <Field {...{
          name: 'endDate',
          label: 'End Date',
          value: formValues.endDate,
          handleChange,
          placeholder: 'mm/dd/yyyy'
        }} />
      </div>
      <div className={styles.fieldGroup}>
        <Field {...{
          name: 'tag',
          label: 'Tag',
          value: formValues.tag,
          handleChange,
        }} />
      </div>
    </>
  )
}

FormFilters.propTypes = {
  formValues: PropTypes.func,
  handleChange: PropTypes.func
}

TagRenewalFilters.propTypes = {
  formValues: PropTypes.func,
  handleChange: PropTypes.func
}

const VehicleInfoFilters = ({ formValues, handleChange }) => {
  return (
    <>
      <div className={styles.fieldGroup}>
        <Field {...{
          name: 'name',
          label: 'Name',
          value: formValues.name,
          handleChange
        }} />
        <Field {...{
          name: 'email',
          label: 'Email',
          value: formValues.email,
          handleChange
        }} />
      </div>
      <div className={styles.fieldGroup}>
        <Field {...{
          name: 'vin',
          label: 'VIN',
          value: formValues.vin,
          handleChange
        }} />
        <Field {...{
          name: 'date',
          label: 'Date',
          value: formValues.date,
          handleChange,
          placeholder: 'mm/dd/yyyy'
        }} />
      </div>
      <div className={styles.fieldGroup}>
        <Field {...{
          name: 'startDate',
          label: 'Start Date',
          value: formValues.startDate,
          handleChange,
          placeholder: 'mm/dd/yyyy'
        }} />
        <Field {...{
          name: 'endDate',
          label: 'End Date',
          value: formValues.endDate,
          handleChange,
          placeholder: 'mm/dd/yyyy'
        }} />
      </div>
      <div className={styles.fieldGroup}>
        <Field {...{
          name: 'make',
          label: 'Make',
          value: formValues.make,
          handleChange,
        }} />
        <Field {...{
          name: 'model',
          label: 'Model',
          value: formValues.model,
          handleChange,
        }} />
      </div>
    </>
  )
}

VehicleInfoFilters.propTypes = {
  formValues: PropTypes.func,
  handleChange: PropTypes.func
}

const FilterForm = ({ filters, setFilters, defaultFilters, current }) => {
  const [open, setOpen] = useState(false)
  const [formValues, setFormValues] = useState(filters)

  function handleChange(e) {
    const value = e.target.value;
    const name = e.target.name;
    setFormValues({ ...formValues, [name]: value })
  }

  function handleClear() {
    setFormValues(defaultFilters)
  }

  function handleClose() {
    setOpen(false)
  }

  function applyFilters() {
    setFilters(formValues)
    setOpen(false)
  }

  useEffect(() => {
    if (open) {
      setFormValues(filters)
    }
  }, [filters, open])

  return (
    <>
      {current > 0 &&<div className={styles.buttonContainer + ` ${styles.filterButton}`}>
        <button className={styles.pageButton} onClick={() => setOpen(true)}>FILTERS</button>
      </div>}
      {open && <div className={styles.modal}>
        <div className={styles.formContainer}>
          <form onSubmit={e => e.preventDefault()}>
            {current === 1 && <TagRenewalFilters {...{ formValues, handleChange }} />}
            {current > 1 && current < 10 && <FormFilters {...{ formValues, handleChange }} />}
            {current === 10 && <VehicleInfoFilters {...{ formValues, handleChange }} />}
            {current === 11 && <BoatRenewalFilters {...{ formValues, handleChange }} />}
            
            <div className={styles.submitContainer}>
              <PortalButton {...{ label: 'APPLY', onClick: applyFilters }} />
              <PortalButton {...{ label: 'CLEAR', onClick: handleClear, className: styles.secondary }} />
              <PortalButton {...{ label: 'CLOSE', onClick: handleClose, className: styles.secondary }} />
            </div>
          </form>
        </div>
      </div>}
    </>
  )
}

FilterForm.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  defaultFilters: PropTypes.object,
  current: PropTypes.number
}

const FilterDisplay = ({ setFilters, filters }) => {
  const chips = Object.entries(filters)
    .filter(([_, value]) => value)
    .map(([key, value], idx) => (
      <div key={idx} className={styles.chip}>
        <span>{`${key}: ${value}`}</span>
        <button onClick={() => setFilters({ ...filters, [key]: '' })}>x</button>
      </div>
    ))


  return (
    <div className={styles.filterDisplay}>
      {chips}
    </div>
  )
}

FilterDisplay.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func,
}

const Router = ({ setCurrent }) => {
  return (
    <div className={styles.router}>
      <div className={styles.routerButtons}>
        <Button {...{
          label: 'Tag renewals',
          onClick: () => setCurrent(1),
          direction: 'right'
        }} />
        <Button {...{
          label: 'Lien holder title',
          onClick: () => setCurrent(2),
          direction: 'right'
        }} />
        <Button {...{
          label: 'Duplicate title',
          onClick: () => setCurrent(3),
          direction: 'right'
        }} />
        <Button {...{
          label: 'Repo instructions',
          onClick: () => setCurrent(4),
          direction: 'right'
        }} />
        <Button {...{
          label: 'Decal replacements',
          onClick: () => setCurrent(5),
          direction: 'right'
        }} />
        <Button {...{
          label: 'Change of address',
          onClick: () => setCurrent(6),
          direction: 'right'
        }} />
        <Button {...{
          label: 'Handicap placard',
          onClick: () => setCurrent(7),
          direction: 'right'
        }} />
        <Button {...{
          label: 'Registration requests',
          onClick: () => setCurrent(8),
          direction: 'right'
        }} />
        <Button {...{
          label: 'Personalized tags',
          onClick: () => setCurrent(9),
          direction: 'right'
        }} />
        <Button {...{
          label: 'Vehicle information',
          onClick: () => setCurrent(10),
          direction: 'right'
        }} />
        <Button {...{
          label: 'Boat renewals',
          onClick: () => setCurrent(11),
          direction: 'right'
        }} />
      </div>
    </div>
  )
}

Router.propTypes = {
  setCurrent: PropTypes.func
}

export default function Portal() {
  const getToken = () => {
    if (typeof window != 'undefined') {
      return window.sessionStorage.getItem("mcntag-token")
    }
    return null
  }

  const [token, setToken] = useState(getToken())
  const [results, setResults] = useState([])
  const [lastResultCount, setLastResultCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [current, setCurrent] = useState(0)

  const tagApi = {
    defaultFilters: {
      vin: '',
      tag: '',
      date: '',
      startDate: '',
      endDate: '',
      total: '',
      rollNumber: '',
      cardHolder :''
    },
    url: 'search-tag-renewals',
    title: 'Tag Renewals',
    loadMoreFilters: {
      lastVin: results[results.length  - 1]?.vin,
      lastDate: results[results.length - 1]?.date,
    }
  }

  const boatApi = {
    defaultFilters: {
      boatNumber: '',
      boatYear: '',
      date: '',
      startDate: '',
      endDate: '',
      fee: '',
      rollNumber: '',
      cardHolder :'',
      name: ''
    },
    url: 'search-boat-renewals',
    title: 'Boat Renewals',
    loadMoreFilters: {
      lastVin: results[results.length  - 1]?.vin,
      lastDate: results[results.length - 1]?.date,
    }
  }

  const formsApi = {
    defaultFilters: {
      name: '',
      cardholder: '',
      date: '',
      startDate: '',
      endDate: '',
      rollNumber: '',
      tag: '',
    },
    loadMoreFilters: {
      lastId: results[results.length  - 1]?.transaction_id,
      lastDate: results[results.length - 1]?.date,
    }
  }
  
  const vehicleInfoApi = {
    defaultFilters: {
      name: '',
      email: '',
      date: '',
      startDate: '',
      endDate: '',
      make: '',
      model: '',
      vin: ''
    },
    url: 'search-vehicle-info',
    title: 'Vehicle Information',
    loadMoreFilters: {
      lastId: results[results.length  - 1]?.transaction_id,
      lastDate: results[results.length - 1]?.date,
    }
  }

  const searchApi = {
    0: { ...tagApi, title: '' },
    1: tagApi,
    2: {
      ...formsApi,
      url: 'search-lien-title-requests',
      title: 'Lien Holder Title Requests',
    },
    3: {
      ...formsApi,
      url: 'search-duplicate-title-requests',
      title: 'Duplicate Title Requests',
    },
    4: {
      ...formsApi,
      url: 'search-repo-instructions',
      title: 'Repo Instructions',
    },
    5: {
      ...formsApi,
      url: 'search-decal-replacements',
      title: 'Decal Replacements',
    },
    6: {
      ...formsApi,
      url: 'search-change-address',
      title: 'Change Address',
    },
    7: {
      ...formsApi,
      url: 'search-disability-placards',
      title: 'Handicap Placards',
    },
    8: {
      ...formsApi,
      url: 'search-registration-requests',
      title: 'Registration Requests',
    },
    9: {
      ...formsApi,
      url: 'search-personalized-tags',
      title: 'Personalized Tags'
    },
    10: vehicleInfoApi,
    11: boatApi
  }

  const [filters, setFilters] = useState(searchApi[current].defaultFilters)

  const clearToken = () => {
    setToken(null)
    window.sessionStorage.removeItem('mcntag-token')
  }
  
  useEffect(() => {
    if (token) {
      const timer = setInterval(() => {
        const decoded = jwt.decode(token)
        const expiration = new Date(decoded.exp * 1000)
        const now = new Date()

        if (expiration.getTime() < now.getTime()) {
          clearToken()
        }
      }, 1000)
      
      return (() => clearInterval(timer))
    }
  }, [token])

  const search = async () => {
    setLoading(true)
    const result = await axios.post(`https://mcntags.com/api/${searchApi[current].url}`,
      { ...filters },
      { headers: { Authorization: `Bearer ${token}` }}
    )

    setLoading(false)

    if (result.status === 401) {
      clearToken()
    } else {
      setResults(result.data.results)
      setLastResultCount(result.data.results.length)
    }
  }

  useEffect(() => {
    if (token && current > 0) {
      search()
    }
  }, [filters, token])

  useEffect(() => {
    setFilters(searchApi[current].defaultFilters)
  }, [current])

  const loadMore = async () => {
    setLoading(true)
    const result = await axios.post(`https://mcntags.com/api/${searchApi[current].url}`,
      {
        ...filters,
        ...searchApi[current].loadMoreFilters
      },
      { headers: { Authorization: `Bearer ${token}` }}
    )

    setLoading(false)
    
    if (result.status === 401) {
      clearToken()
    } else {
      setResults([...results, ...result.data.results])
      setLastResultCount(result.data.results.length)
    }
  }
  
  const logout = async () => {
    setToken(null)
    setFilters(searchApi[current].defaultFilters)
    window.sessionStorage.removeItem('mcntag-token')
  }

  const goBack = () => {
    if (current > 0) {
      setResults([])
      setLastResultCount(0)
      setCurrent(0)
    } else {
      navigate('/')
    }
  }

  const TopButton = ({ action, label }) => (
    <div className={styles.buttonContainer + ` ${styles.filterButton}`}>
      <button className={styles.pageButton} onClick={action}>{label}</button>
    </div>
  )

  TopButton.propTypes = {
    action: PropTypes.func,
    label: PropTypes.string
  }

  return (
    <section className={styles.section}>
      <Header title='Admin Portal' />
      {token && <>
        <h2 className={styles.title}>{searchApi[current].title}</h2>
        <div className={styles.topButtons}>
          <FilterDisplay {...{ setFilters, filters }} />
          <FilterForm { ...{
            filters,
            setFilters,
            defaultFilters: searchApi[current].defaultFilters,
            current,
          }} />
          <TopButton {...{ action: search, label: 'reload'}} />
          <TopButton {...{ action: goBack, label: 'back'}} />
          <TopButton {...{ action: logout, label: 'logout'}} />
        </div>
        {current === 0 && <Router {...{ setCurrent }} />}
  
        {current === 1 && <>
          {results.map((transaction, idx) => (
            <TagTransaction key={transaction.transaction_id} {...{
              transaction,
              key: transaction.transaction_id,
              showBorder: idx === results.length - 1,
              token
            }} />
          ))}
        </>}
        
        {current > 1 && current < 10 && <>
          {results.map((transaction, idx) => (
            <FormTransaction key={transaction.transaction_id} {...{
              transaction,
              showBorder: idx === results.length - 1,
              token
            }} />
          ))}
        </>}
        
        {current === 10 && <>
          {results.map((transaction, idx) => (
            <VehicleInfoTransaction key={transaction.transaction_id} {...{
              transaction,
              showBorder: idx === results.length - 1,
              token
            }} />
          ))}
        </>}

        {current === 11 && <>
          {results.map((transaction, idx) => (
            <BoatTransaction key={transaction.transaction_id} {...{
              transaction,
              showBorder: idx === results.length - 1,
              token
            }} />
          ))}
        </>}

        {current > 0 && <>
          {results.length === 0 && !loading && <p className={styles.empty}>No results found</p>}
          {loading && <Loading />}

          {lastResultCount === MAX_LOAD && <div className={styles.buttonContainer}>
            <button className={styles.pageButton} onClick={loadMore}>load more</button>
          </div>}
        </>}
      </>}

      {!token && <LoginForm {...{ setToken }} />}
      <Footer />
    </section>
  )
}