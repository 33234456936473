import React from 'react'
import Layout from '../components/layout/layout'
import Portal from '../components/portal/portal'

export default function PortalPage() {
  return (
    <Layout>
      <Portal />
    </Layout>
  );
}
